import React, { useEffect, useRef, useState } from 'react';
import * as api from '../../api';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import { CSVLink } from 'react-csv';
import Loader from '../../Components/Loader';
import moment from 'moment-timezone';

import './index.scss';

moment.locale('en');
momentLocalizer();

const ExportCSV = () => {
  const datesRequired = 'Select start and end dates to run reports.';
  const invalidDates = 'The end date must occur after the start date.';

  const [csvData, setCsvData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loaded, setLoaded] = useState(true);
  const [filename, setFilename] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [instructions, setInstructions] = useState(datesRequired);
  const csvLink = useRef();

  useEffect(() => {
    if (!filename || !csvData) {
      return;
    }
    csvLink.current.link.click();
    setLoaded(true);
    setFilename('');
    setCsvData([]);
  }, [filename, csvData]);

  useEffect(() => {
    if (!startDate || !endDate) {
      setInstructions(datesRequired);
      setDisabled(true);
    } else if (startDate > endDate) {
      setInstructions(invalidDates);
      setDisabled(true);
    } else {
      setInstructions('\u00A0');
      setDisabled(false);
    }
  }, [startDate, endDate]);

  const getCSVRidesData = (e) => {
    e.preventDefault();
    doGetCSVRidesData('rides');
  };

  const getCSVRideSurchargesData = (e) => {
    e.preventDefault();
    doGetCSVRidesData('ride_surcharges');
  };

  const doGetCSVRidesData = (csv_type) => {
    setLoaded(false);
    const start = moment(startDate).tz('America/Los_Angeles').format('MMM DD YYYY');
    const end = moment(endDate).tz('America/Los_Angeles').format('MMM DD YYYY');
    return api
      .getRidesCSV(start, end, csv_type)
      .then((data) => {
        const csvFilename = `${csv_type}_${start}_to_${end}`;
        setCsvData(data);
        setFilename(csvFilename);
      })
      .catch((error) => {
        alert('Error: There was an error generating CSV', error);
        setLoaded(true);
      });
  };

  return (
    <AuthenticatedPage>
      <div className="page-title">Export CSV</div>
      <p>{instructions}</p>
      <form>
        <div className="datesContainer">
          <div className="dateSelectorContainer">
            start:
            <DateTimePicker
              className="add_pricing_plan_form__data_input"
              defaultValue={startDate}
              onChange={(value) => setStartDate(value)}
            />
          </div>
          <div className="dateSelectorContainer">
            end:
            <DateTimePicker
              className="add_pricing_plan_form__data_input"
              defaultValue={endDate}
              onChange={(value) => setEndDate(value)}
            />
          </div>
        </div>
        <div className="buttonContainer">
          <button
            className={disabled ? 'downloadBtn disabled' : 'downloadBtn'}
            onClick={getCSVRidesData}
            disabled={disabled}>
            Download Rides CSV
          </button>
          <button
            className={disabled ? 'downloadBtn disabled' : 'downloadBtn'}
            onClick={getCSVRideSurchargesData}
            disabled={disabled}>
            Download Ride Surcharges CSV
          </button>
        </div>
      </form>
      <Loader loaded={loaded} />
      <CSVLink
        data={csvData}
        filename={filename}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </AuthenticatedPage>
  );
};

export default ExportCSV;
